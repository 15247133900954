import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { observable, makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { ReactComponent as EigenticLogo } from '../Core/Logo/Eigentic.svg';
import SignupForm from './SignupForm';

@inject('store')
@observer
class Signup extends Component {
  @observable email = ""
  @observable password = ""
  @observable fname = ""
  @observable lname = ""
  @observable errorMessage = ""
  @observable successMessage = ""
  @observable isSigningUp = false

  constructor() {
    super()
    makeObservable(this)
  }

  handleLogoClick = () => {
    this.props.history.push('/home');
  }

  handleSupportClick = (e) => {
    e.preventDefault();
    window.location.href = 'mailto:contact@eigentic.com';
  }

  onChangeAny = (val, attr) => {
    this[attr] = val
    this.errorMessage = ""
    this.successMessage = ""
  }

  onSignup = async (e) => {
    try {
      e.preventDefault()
      this.isSigningUp = true
      this.errorMessage = ""
      this.successMessage = ""
      
      const { data } = await this.props.store.api.post('/auth/signup', {
        email: this.email,
        password: this.password,
        fname: this.fname,
        lname: this.lname,
      });

      // Handle success message from backend
      if (data.message) {
        this.successMessage = data.message;
        // Clear form fields after successful signup
        this.email = "";
        this.password = "";
        this.fname = "";
        this.lname = "";
      }
    } catch (err) {
      console.error('Signup error:', err);
      if (err?.response?.data?.message) {
        this.errorMessage = err.response.data.message;
      } else {
        this.errorMessage = "An error occurred during signup. Please try again.";
      }
    } finally {
      this.isSigningUp = false
    }
  }

  render() {
    return (
      <div className="min-h-screen flex">
        <Helmet>
          <title>Signup - Eigentic</title>
        </Helmet>

        {/* Left Panel */}
        <div className="hidden lg:flex lg:w-1/2 bg-[#F5F7FF]">
          <div className="flex flex-col justify-center items-center w-full p-12">
            <div className="text-center cursor-pointer" onClick={this.handleLogoClick}>
              <EigenticLogo className="h-48 w-auto mb-8" />
            </div>
          </div>
        </div>

        {/* Right Panel */}
        <div className="w-full lg:w-1/2 flex flex-col justify-center items-center p-8 bg-white">
          <div className="w-full max-w-md">
            {/* Mobile Logo */}
            <div className="lg:hidden flex justify-center mb-8 cursor-pointer" onClick={this.handleLogoClick}>
              <EigenticLogo className="h-16 w-auto" />
            </div>

            <div className="bg-white rounded-xl border border-gray-100 p-8">
              <div className="text-center mb-8">
                <h2 className="text-2xl font-bold text-gray-900">
                  Create your account
                </h2>
                <p className="text-gray-600 mt-2">
                  Enter your details to create your account
                </p>
              </div>

              <SignupForm
                email={this.email}
                password={this.password}
                fname={this.fname}
                lname={this.lname}
                isSigningUp={this.isSigningUp}
                onChange={this.onChangeAny}
                onSubmit={this.onSignup}
              />

              {this.successMessage && (
                <div className="mt-4 text-sm p-4 rounded-lg text-green-700 bg-green-100">
                  {this.successMessage}
                </div>
              )}

              {this.errorMessage && (
                <div className="mt-4 text-sm p-4 rounded-lg text-red-700 bg-red-100">
                  {this.errorMessage}
                </div>
              )}
            </div>

            <div className="mt-8 text-center space-y-4">
              <p className="text-sm text-gray-600">
                Already have an account?{' '}
                <a 
                  href="/login" 
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push('/login');
                  }}
                  className="text-indigo-600 hover:text-indigo-700 font-medium"
                >
                  Sign in
                </a>
              </p>
              <p className="text-sm text-gray-600">
                Need help?{' '}
                <a 
                  href="mailto:contact@eigentic.com" 
                  onClick={this.handleSupportClick}
                  className="text-indigo-600 hover:text-indigo-700 font-medium"
                >
                  Contact support
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Signup