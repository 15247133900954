import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { observable, makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { ReactComponent as EigenticLogo } from '../Core/Logo/Eigentic.svg';
import LoginForm from './LoginForm';

@inject('store')
@observer
class Login extends Component {
  @observable email = ""
  @observable password = ""
  @observable errorMessage = ""
  @observable isLoading = false

  constructor() {
    super()
    makeObservable(this)
  }

  handleLogoClick = () => {
    this.props.history.push('/home');
  }

  handleSupportClick = (e) => {
    e.preventDefault();
    window.location.href = 'mailto:contact@eigentic.com';
  }

  onChangeAny = (val, attr) => {
    this[attr] = val
    this.errorMessage = ""
  }

  onLogin = async (e) => {
    try {
      e.preventDefault()
      this.isLoading = true
      
      const { data } = await this.props.store.api.post('/auth/signin', {
        email: this.email,
        password: this.password,
      });

      if (data.profile.permissions && 
          data.profile.permissions.length === 1 && 
          data.profile.permissions.includes('candidate')) {
        this.errorMessage = "This login is for recruiters only. Candidates should use the interview-specific login link sent to their email.";
        return;
      }

      this.props.store.loginWithDataTokenAndProfile(data);
    } catch (err) {
      console.error('Login error:', err);
      if (err?.response?.data?.message) {
        this.errorMessage = err.response.data.message;
      } else {
        this.errorMessage = "An error occurred during login. Please try again.";
      }
    } finally {
      this.isLoading = false
    }
  }

  render() {
    return (
      <div className="min-h-screen flex">
        <Helmet>
          <title>Login - Eigentic</title>
        </Helmet>

        {/* Left Panel */}
        <div className="hidden lg:flex lg:w-1/2 bg-[#F5F7FF]">
          <div className="flex flex-col justify-center items-center w-full p-12">
            <div className="text-center cursor-pointer" onClick={this.handleLogoClick}>
              <EigenticLogo className="h-48 w-auto mb-8" />
            </div>
          </div>
        </div>

        {/* Right Panel */}
        <div className="w-full lg:w-1/2 flex flex-col justify-center items-center p-8 bg-white">
          <div className="w-full max-w-md">
            {/* Mobile Logo */}
            <div className="lg:hidden flex justify-center mb-8 cursor-pointer" onClick={this.handleLogoClick}>
              <EigenticLogo className="h-16 w-auto" />
            </div>

            <div className="bg-white rounded-xl border border-gray-100 p-8">
              <div className="text-center mb-8">
                <h2 className="text-2xl font-bold text-gray-900">
                  Sign in to your account
                </h2>
                <p className="text-gray-600 mt-2">
                  Enter your credentials to access your account
                </p>
              </div>

              <LoginForm
                email={this.email}
                password={this.password}
                isLoading={this.isLoading}
                onChange={this.onChangeAny}
                onSubmit={this.onLogin}
              />

              {this.errorMessage && (
                <div className={`mt-4 text-sm p-4 rounded-lg ${
                  this.errorMessage.includes("Email sent")
                    ? "text-green-700 bg-green-100"
                    : "text-red-700 bg-red-100"
                }`}>
                  {this.errorMessage}
                  {this.errorMessage.includes("Candidates should") && (
                    <p className="mt-2 text-gray-600">
                      Please check your email for the interview-specific login link.
                    </p>
                  )}
                </div>
              )}
            </div>

            <div className="mt-8 text-center">
              <p className="text-sm text-gray-600">
                Need help?{' '}
                <a 
                  href="mailto:contact@eigentic.com" 
                  onClick={this.handleSupportClick}
                  className="text-indigo-600 hover:text-indigo-700 font-medium"
                >
                  Contact support
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);