import React, { useEffect, useState } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { CheckIcon, CreditCardIcon, UserIcon, LogoutIcon } from '@heroicons/react/outline';
import Layout from '../Components/Layout/Layout';
import Pricing from '../Pricing';
import Feedback from './Feedback';
import ManageSubscription from './ManageSubscription';

const MyProfile = inject('store')(observer(({ store }) => {
  const [plan, setPlan] = useState({ status: '', plan: '' });

  useEffect(() => {
    store.refreshTokenAndProfile();
    initPlan();
  }, []);

  const initPlan = async () => {
    const res = await store.api.post("/user/stripe/plan");
    setPlan(res.data);
  };

  const isPro = plan.plan === 'Pro';

  return (
    <Layout>
      <div className="bg-gray-100 min-h-screen">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-extrabold text-gray-900 text-center mb-8">My Profile</h1>
          
          <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
            <div className="px-4 py-5 sm:px-6">
              <h2 className="text-lg leading-6 font-medium text-gray-900">Account Information</h2>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and plan</p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Full name</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{`${store.profile.fname} ${store.profile.lname}`}</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Email address</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{store.profile.email}</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Current plan</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${isPro ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'}`}>
                      {isPro ? 'Pro' : 'Free'}
                    </span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          <div className="mt-8 grid gap-5 max-w-lg mx-auto lg:grid-cols-2 lg:max-w-none">
            {/* <ProfileCard
              icon={CreditCardIcon}
              title="Manage Subscription"
              description="Upgrade, downgrade, or update your payment details"
              linkTo="/my-profile/manage-subscription"
              color="indigo"
            /> */}
            <ProfileCard
              icon={UserIcon}
              title="Account Settings"
              description="Update your profile information"
              linkTo="/my-profile/settings"
              color="blue"
            />
            
          </div>
        </div>
      </div>

      <Switch>
	  <Route exact path="/my-profile/manage-subscription" component={ManageSubscription} />
	  <Route exact path="/my-profile/feedback" component={Feedback} />
      </Switch>
    </Layout>
  );
}));

const ProfileCard = ({ icon: Icon, title, description, linkTo, onClick, color }) => {
  const colorClasses = {
    indigo: 'bg-indigo-600 hover:bg-indigo-700',
    blue: 'bg-blue-600 hover:bg-blue-700',
    green: 'bg-green-600 hover:bg-green-700',
    red: 'bg-red-600 hover:bg-red-700',
  };

  const CardContent = () => (
    <>
      <div className="flex-shrink-0">
        <Icon className="h-6 w-6 text-white" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-white">{title}</h3>
        <p className="mt-1 text-sm text-gray-300">{description}</p>
      </div>
    </>
  );

  return linkTo ? (
    <Link
      to={linkTo}
      className={`flex items-center p-5 rounded-lg shadow-md hover:shadow-lg transition duration-150 ease-in-out ${colorClasses[color]}`}
    >
      <CardContent />
    </Link>
  ) : (
    <button
      onClick={onClick}
      className={`flex items-center p-5 rounded-lg shadow-md hover:shadow-lg transition duration-150 ease-in-out ${colorClasses[color]} w-full text-left`}
    >
      <CardContent />
    </button>
  );
};

export default MyProfile;