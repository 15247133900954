import React from 'react';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import Lottie from 'lottie-react';
import bulkResumeAnimation from './Animation/bulk-resume-animation.json';
import resumeScanningAnimation from './Animation/resume-scanning-animation.json';
import selectResumeAnimation from './Animation/select-resume-animation.json';
import virtualInterviewAnimation from './Animation/virtual-interview-animation.json';

// Keep your existing Header and Hero components unchanged

const WorkflowAnimation = () => {
  const animations = [
    { data: bulkResumeAnimation, title: "Resume Upload" },
    { data: resumeScanningAnimation, title: "AI Resume Scoring" },
    { data: virtualInterviewAnimation, title: "Automated AI Interviews" },

    { data: selectResumeAnimation, title: "Smart Candidate Selection" }
  ];

  return (
    <div className="bg-white py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-10 text-gray-800">Our AI-Powered Recruitment Workflow</h2>
        <div className="flex flex-wrap justify-center items-start gap-8">
          {animations.map((anim, index) => (
            <motion.div
              key={index}
              className="w-64 text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Lottie 
                animationData={anim.data} 
                className="w-full h-48 mb-4"
                loop={true}
              />
              <h3 className="text-lg font-semibold text-gray-700">{anim.title}</h3>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default WorkflowAnimation;
