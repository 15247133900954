// components/ContactSection.js
import React, { Component } from 'react';
import { observable, makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { motion } from 'framer-motion';

@inject('store')
@observer
class ContactSection extends Component {
  @observable formData = {
    name: '',
    email: '',
    company: '',
    message: ''
  }
  @observable status = {
    submitting: false,
    error: null,
    success: null
  }

  constructor() {
    super()
    makeObservable(this)
  }

  handleChange = (e) => {
    this.formData[e.target.name] = e.target.value
    this.status.error = null
    this.status.success = null
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.status.submitting = true;

    try {
      const { data } = await this.props.store.api.post('/auth/contact', this.formData);

      // Clear form on success
      this.formData = {
        name: '',
        email: '',
        company: '',
        message: ''
      };

      this.status.success = data.message || "Message sent successfully!";
      this.status.error = null;

    } catch (err) {
      console.error('Contact form error:', err);
      this.status.error = err?.response?.data?.message || "Failed to send message. Please try again.";
      this.status.success = null;
    } finally {
      this.status.submitting = false;
    }
  }

  render() {
    return (
      <div id="contact" className="py-24 bg-gradient-to-b from-gray-50 to-white">
        <div className="container mx-auto px-6">
          <div className="max-w-6xl mx-auto">
            {/* Header */}
            <div className="text-center mb-16">
              <motion.h2 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-4xl font-bold text-gray-900 mb-4"
              >
                Let's Transform Your Hiring Process
              </motion.h2>
              <motion.p 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                className="text-xl text-gray-600 max-w-2xl mx-auto"
              >
                Ready to revolutionize your recruitment? Get in touch with us today.
              </motion.p>
            </div>

            {/* Contact Form */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-white rounded-2xl shadow-xl p-8 md:p-12"
            >
              <form onSubmit={this.handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Left Column */}
                <div className="space-y-6">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                      Full Name *
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      required
                      value={this.formData.name}
                      onChange={this.handleChange}
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="John Doe"
                    />
                  </div>

                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                      Email Address *
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      required
                      value={this.formData.email}
                      onChange={this.handleChange}
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="john@company.com"
                    />
                  </div>

                  <div>
                    <label htmlFor="company" className="block text-sm font-medium text-gray-700 mb-1">
                      Company Name
                    </label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      value={this.formData.company}
                      onChange={this.handleChange}
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="Your Company Ltd."
                    />
                  </div>
                </div>

                {/* Right Column */}
                <div className="space-y-6">
                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                      Message *
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      required
                      value={this.formData.message}
                      onChange={this.handleChange}
                      rows="8"
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="Tell us about your needs..."
                    />
                  </div>

                  <button
                    type="submit"
                    disabled={this.status.submitting}
                    className={`w-full py-3 px-6 rounded-lg text-white font-semibold transition-all duration-300 ${
                      this.status.submitting
                        ? 'bg-indigo-400 cursor-not-allowed'
                        : 'bg-indigo-600 hover:bg-indigo-700'
                    }`}
                  >
                    {this.status.submitting ? 'Sending...' : 'Send Message'}
                  </button>

                  {/* Status Messages */}
                  {this.status.success && (
                    <div className="text-green-600 text-center p-4 bg-green-50 rounded-lg">
                      {this.status.success}
                    </div>
                  )}

                  {this.status.error && (
                    <div className="text-red-600 text-center p-4 bg-red-50 rounded-lg">
                      {this.status.error}
                    </div>
                  )}
                </div>
              </form>
            </motion.div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactSection;