// Import statements at top level
import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import Lottie from "react-lottie";
import { ReactComponent as EigenticLogo } from './Logo/Eigentic.svg';
import RecruitmentAnimation from './RecruitmentAnimation';
import WorkflowAnimation from './WorkFlowAnimation';
import msBadge from '../Core/Image/Partner badges/MS_Startups_Celebration_Badge_Dark.png';
import  ContactSection from'./ContactSection'

import { 
  UserCircleIcon, 
  DocumentSearchIcon ,
  DocumentReportIcon ,
  LightBulbIcon, 
  AcademicCapIcon, 
  ClockIcon,
  ChartBarIcon,
  UserGroupIcon,
  DocumentTextIcon,
  VideoCameraIcon
} from '@heroicons/react/outline';

// Animation data

// Components
const Header = () => (
  <motion.header
    initial={{ y: -100 }}
    animate={{ y: 0 }}
    transition={{ duration: 0.5 }}
    className="fixed top-0 left-0 right-0 z-50 bg-white shadow-sm"
  >
    <div className="container mx-auto px-6 flex justify-between items-center h-20"> {/* Increased container height */}
      <div className="flex items-center">
        <EigenticLogo className="h-28" /> {/* Increased logo height */}
      </div>
      <nav className="hidden md:flex items-center space-x-10">
      <Link 
          to="about-us" 
          smooth={true} 
          duration={500} 
          className="text-base font-semibold text-gray-700 hover:text-indigo-600 transition duration-300 cursor-pointer"
        >
          About Us
        </Link>
        
        <Link 
          to="company-features" 
          smooth={true} 
          duration={500} 
          className="text-base font-semibold text-gray-700 hover:text-indigo-600 transition duration-300 cursor-pointer"
        >
          For Companies
        </Link>
        
        <Link 
          to="candidate-features" 
          smooth={true} 
          duration={500} 
          className="text-base font-semibold text-gray-700 hover:text-indigo-600 transition duration-300 cursor-pointer"
        >
          For Candidates
        </Link>
      
      <Link 
          to="pricing" 
          smooth={true} 
          duration={500}
          className="text-base font-semibold text-gray-700 hover:text-indigo-600 transition duration-300 cursor-pointer"
        >
          Pricing
        </Link>
        
           </nav>
      <div className="flex items-center space-x-4">
        <NavLink 
          to="/Signup" 
          className="bg-white text-indigo-600 border-2 border-indigo-600 px-6 py-2.5 rounded-full font-semibold hover:bg-indigo-50 transition duration-300"
        >
          Free Trial
        </NavLink>
        <NavLink 
          to="/login" 
          className="bg-indigo-600 text-white px-6 py-2.5 rounded-full font-semibold hover:bg-indigo-700 transition duration-300"
        >
          Sign In
        </NavLink>
      </div>
    </div>
  </motion.header>
);

const Hero = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className="bg-gradient-to-br from-indigo-600 via-indigo-700 to-purple-800 text-white pt-32 pb-24">
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <motion.div 
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="md:w-1/2 mb-12 md:mb-0"
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              Transform Your Talent Journey
            </h1>
            <p className="text-xl mb-8 text-indigo-100">
              For Companies: Streamline hiring with AI-powered resume screening, scoring, and automated interviews.
              <br/><br/>
              For Candidates: Practice interviews, improve skills, and land your dream job.
            </p>
            <div className="flex space-x-4">
              <Link 
                to="company-features"
                smooth={true}
                duration={500}
                offset={-100}
                className="bg-white text-indigo-600 px-8 py-4 rounded-full text-lg font-semibold hover:bg-indigo-50 transition duration-300 cursor-pointer"
              >
                For Companies
              </Link>
              <Link
                to="candidate-features"
                smooth={true}
                duration={500}
                offset={-100}
                className="bg-indigo-500 bg-opacity-20 text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-opacity-30 transition duration-300 cursor-pointer"
              >
                For Candidates
              </Link>
            </div>
          </motion.div>
          <motion.div 
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="md:w-1/2 relative"
          >
            <div className="relative w-full h-96 rounded-2xl overflow-hidden bg-gradient-to-r from-indigo-500/10 to-purple-500/10">
              <div className="absolute inset-0 flex items-center justify-center">
              <motion.div 
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
          className="md:w-1/2 flex justify-center relative"
        >
          <div className="absolute inset-0 bg-blue-500 opacity-20 rounded-full filter blur-3xl"></div>
          <RecruitmentAnimation />
        </motion.div>

              </div>
              <div className="absolute top-0 right-0 w-72 h-72 bg-indigo-500 rounded-full filter blur-3xl opacity-10 animate-pulse"></div>
              <div className="absolute bottom-0 left-0 w-72 h-72 bg-purple-500 rounded-full filter blur-3xl opacity-10 animate-pulse delay-700"></div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};


const CompanyFeatures = () => (
  <div id="company-features" className="py-24 bg-gray-50">
    <div className="container mx-auto px-6">
      <div className="text-center mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">Streamline Your Hiring Process</h2>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          Harness AI technology to transform your recruitment workflow
        </p>
      </div>
      <div className="grid md:grid-cols-3 gap-8">
        <FeatureCard 
          icon={DocumentTextIcon}
          title="Smart Resume Parsing"
          description="Extract structured data from resumes automatically with our advanced AI technology. Save hours of manual data entry."
        />
        <FeatureCard 
          icon={ChartBarIcon}
          title="Intelligent Scoring"
          description="Automatically evaluate and rank candidates based on job requirements using our AI-powered scoring system."
        />
        <FeatureCard 
          icon={VideoCameraIcon}
          title="Automated Interviews"
          description="Conduct preliminary interviews with AI to screen candidates efficiently and reduce hiring time."
        />
        <FeatureCard 
          icon={DocumentSearchIcon}
          title="JD Analysis"
          description="Break down job descriptions into key requirements and match them with candidate profiles automatically."
        />
        <FeatureCard 
          icon={UserGroupIcon}
          title="Candidate Ranking"
          description="Get an intelligent ranking of candidates based on multiple parameters and job fit scores."
        />
        <FeatureCard 
          icon={DocumentReportIcon}
          title="Detailed Analytics"
          description="Access comprehensive reports and insights about your hiring pipeline and candidate pool."
        />
      </div>
    </div>
  </div>
);

const CandidateFeatures = () => (
  <div id="candidate-features" className="py-24 bg-white">
    <div className="container mx-auto px-6">
      <div className="text-center mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800">Accelerate Your Career Growth</h2>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          Practice and improve your interview skills with AI-powered tools
        </p>
      </div>
      <div className="flex flex-col md:flex-row items-center gap-12">
        <motion.div 
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          className="md:w-1/2"
        >
          <h2 className="text-3xl font-bold mb-8 text-gray-800">Practice Makes Perfect</h2>
          <div className="space-y-6">
            <div className="flex items-start space-x-4">
              <div className="bg-indigo-100 p-3 rounded-full">
                <VideoCameraIcon className="h-6 w-6 text-indigo-600" />
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">AI-Powered Mock Interviews</h3>
                <p className="text-gray-600">Practice with our AI interviewer anytime, anywhere. Get instant feedback on your responses.</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div className="bg-indigo-100 p-3 rounded-full">
                <AcademicCapIcon className="h-6 w-6 text-indigo-600" />
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">Industry-Specific Training</h3>
                <p className="text-gray-600">Access interview questions and scenarios tailored to your industry and role.</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div className="bg-indigo-100 p-3 rounded-full">
                <ChartBarIcon className="h-6 w-6 text-indigo-600" />
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">Performance Analytics</h3>
                <p className="text-gray-600">Track your progress and identify areas for improvement with detailed feedback.</p>
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div 
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          className="md:w-1/2"
        >
          <div className="bg-gradient-to-r from-indigo-500 to-purple-600 p-8 rounded-2xl text-white">
            <div className="space-y-6">
              <div className="text-center p-6 bg-white/10 rounded-xl backdrop-blur-lg">
                <h4 className="text-2xl font-bold mb-2">95%</h4>
                <p>Users report increased interview confidence</p>
              </div>
              <div className="text-center p-6 bg-white/10 rounded-xl backdrop-blur-lg">
                <h4 className="text-2xl font-bold mb-2">10,000+</h4>
                <p>Practice interviews conducted</p>
              </div>
              <div className="text-center p-6 bg-white/10 rounded-xl backdrop-blur-lg">
                <h4 className="text-2xl font-bold mb-2">85%</h4>
                <p>Success rate in actual interviews</p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  </div>
);

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div 
    whileHover={{ y: -8 }}
    className="bg-white p-8 rounded-xl shadow-lg border border-gray-100"
  >
    <div className="bg-blue-100 w-16 h-16 rounded-full flex items-center justify-center mb-6">
      <Icon className="h-8 w-8 text-blue-600"/>
    </div>
    <h3 className="text-xl font-semibold mb-4 text-gray-800">{title}</h3>
    <p className="text-gray-600 leading-relaxed">{description}</p>
  </motion.div>
);


const PricingSection = () => {
  const companyPlans = [
    {
      name: "Professional",
      price: "$299",
      period: "per month",
      description: "For growing companies",
      features: [
        "Up to 200 AI interviews/month",
        "Up to 500 resume screening",
        "Priority support",
        "5 admin users",
        "Advanced analytics",
        "Custom branding",
        "API access"
      ],
      buttonType: "trial",
      cta: "Start Free Trial",
      highlighted: true
    },
    {
      name: "Enterprise",
      price: "Custom",
      period: "per month",
      description: "For large organizations",
      features: [
        "Unlimited AI interviews",
        "Unlimited resume screening",
        "24/7 support",
        "Unlimited users",
        "Custom integrations",
        "Dedicated account manager",
        "SLA guarantee"
      ],
      buttonType: "contact",
      cta: "Contact Sales",
      highlighted: false
    }
  ];

  const candidatePlan = {
    name: "Candidate Pro",
    price: "$20",
    period: "per month",
    description: "For job seekers",
    features: [
      "Unlimited AI practice interviews",
      "Custom learning roadmap",
      "Personalized feedback",
      "Industry-specific questions",
      "Performance analytics"
    ],
    buttonType: "get-started",
    cta: "Get Started",
    highlighted: false
  };

  const handleButtonClick = (buttonType) => {
    switch (buttonType) {
      case 'get-started':
      case 'trial':
        return '/Signup';
      case 'contact':
        return 'mailto:contact@eigentic.com';
      default:
        return '/Signup';
    }
  };

  return (
    <div id="pricing" className="py-24 bg-gray-50">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Simple, Transparent Pricing
          </h2>
          <p className="text-xl text-gray-600">
            Choose the plan that's right for you
          </p>
        </div>

        {/* Company Plans Section */}
        <div className="mb-20">
          <h3 className="text-2xl font-bold text-gray-800 text-center mb-12">For Companies</h3>
          <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
            {companyPlans.map((plan) => (
              <div 
                key={plan.name}
                className={`${
                  plan.highlighted 
                    ? 'bg-white border-2 border-indigo-600 shadow-xl scale-105' 
                    : 'bg-white border border-gray-200'
                } rounded-2xl p-8 transition-all duration-300 hover:shadow-lg relative`}
              >
                {plan.highlighted && (
                  <div className="absolute top-0 right-0 -translate-y-1/2 translate-x-1/4">
                    <span className="bg-indigo-600 text-white px-4 py-1 rounded-full text-sm font-medium">
                      Most Popular
                    </span>
                  </div>
                )}
                <div className="text-center">
                  <h3 className="text-2xl font-bold text-gray-900 mb-2">{plan.name}</h3>
                  <p className="text-gray-600 mb-6">{plan.description}</p>
                  <div className="mb-6">
                    <span className="text-4xl font-bold text-gray-900">{plan.price}</span>
                    <span className="text-gray-600">/{plan.period}</span>
                  </div>
                </div>
                
                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-center text-gray-600">
                      <svg className="w-5 h-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
                
                {plan.buttonType === 'contact' ? (
                  <a
                    href={handleButtonClick(plan.buttonType)}
                    className={`block w-full py-3 px-6 rounded-full text-center font-semibold transition-all duration-300 ${
                      plan.highlighted
                        ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                        : 'bg-white text-indigo-600 border-2 border-indigo-600 hover:bg-indigo-50'
                    }`}
                  >
                    {plan.cta}
                  </a>
                ) : (
                  <NavLink
                    to={handleButtonClick(plan.buttonType)}
                    className={`block w-full py-3 px-6 rounded-full text-center font-semibold transition-all duration-300 ${
                      plan.highlighted
                        ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                        : 'bg-white text-indigo-600 border-2 border-indigo-600 hover:bg-indigo-50'
                    }`}
                  >
                    {plan.cta}
                  </NavLink>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Candidate Plan Section */}
        <div>
          <h3 className="text-2xl font-bold text-gray-800 text-center mb-12">For Candidates</h3>
          <div className="max-w-md mx-auto">
            <div className="bg-white border border-gray-200 rounded-2xl p-8 transition-all duration-300 hover:shadow-lg">
              <div className="text-center">
                <h3 className="text-2xl font-bold text-gray-900 mb-2">{candidatePlan.name}</h3>
                <p className="text-gray-600 mb-6">{candidatePlan.description}</p>
                <div className="mb-6">
                  <span className="text-4xl font-bold text-gray-900">{candidatePlan.price}</span>
                  <span className="text-gray-600">/{candidatePlan.period}</span>
                </div>
              </div>
              
              <ul className="space-y-4 mb-8">
                {candidatePlan.features.map((feature) => (
                  <li key={feature} className="flex items-center text-gray-600">
                    <svg className="w-5 h-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
              
              <NavLink
                to={handleButtonClick(candidatePlan.buttonType)}
                className="block w-full py-3 px-6 rounded-full text-center font-semibold transition-all duration-300 bg-indigo-600 text-white hover:bg-indigo-700"
              >
                {candidatePlan.cta}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AboutUs = () => (
  <div id="about-us" className="py-24 bg-white">
    <div className="container mx-auto px-6">
      {/* Vision and Mission Section */}
      <div className="text-center mb-16">
        <h2 className="text-4xl font-bold text-gray-900 mb-6">
          Pioneering AI-Driven Talent Solutions
        </h2>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          At Eigentic, we're bridging the gap between exceptional talent and innovative companies through cutting-edge AI technology.
        </p>
      </div>

      {/* Core Values Grid */}
      <div className="grid md:grid-cols-3 gap-8 mb-20">
        <div className="bg-gradient-to-br from-indigo-50 to-white p-8 rounded-2xl">
          <div className="bg-indigo-100 w-12 h-12 rounded-lg flex items-center justify-center mb-6">
            <svg className="w-6 h-6 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
          </div>
          <h3 className="text-xl font-bold text-gray-900 mb-3">Innovation First</h3>
          <p className="text-gray-600">
            Leveraging advanced eigenvalue decomposition and machine learning to revolutionize talent acquisition and development.
          </p>
        </div>

        <div className="bg-gradient-to-br from-purple-50 to-white p-8 rounded-2xl">
          <div className="bg-purple-100 w-12 h-12 rounded-lg flex items-center justify-center mb-6">
            <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </div>
          <h3 className="text-xl font-bold text-gray-900 mb-3">Human-Centric</h3>
          <p className="text-gray-600">
            Combining AI precision with human insight to create meaningful connections between talent and opportunity.
          </p>
        </div>

        <div className="bg-gradient-to-br from-blue-50 to-white p-8 rounded-2xl">
          <div className="bg-blue-100 w-12 h-12 rounded-lg flex items-center justify-center mb-6">
            <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
            </svg>
          </div>
          <h3 className="text-xl font-bold text-gray-900 mb-3">Trusted Excellence</h3>
          <p className="text-gray-600">
            Setting new standards in recruitment technology with enterprise-grade security and reliability.
          </p>
        </div>
      </div>

      {/* Stats and Impact Section */}
      <div className="bg-gradient-to-r from-indigo-600 to-blue-700 rounded-3xl p-12 text-white">
        <div className="text-center mb-12">
          <h3 className="text-3xl font-bold mb-4">Building the Future of Recruitment</h3>
          <p className="text-indigo-100 max-w-2xl mx-auto">
            Backed by Microsoft for Startups, we're bringing cutting-edge AI technology to transform the recruitment landscape
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 text-center">
          <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6">
            <div className="flex items-center justify-center mb-4">
              <svg className="w-8 h-8 text-indigo-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
              </svg>
            </div>
            <h4 className="text-xl font-semibold mb-2">Innovation Ready</h4>
            <p className="text-indigo-100">Advanced AI algorithms ready to transform your hiring process</p>
          </div>

          <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6">
            <div className="flex items-center justify-center mb-4">
              <svg className="w-8 h-8 text-indigo-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
              </svg>
            </div>
            <h4 className="text-xl font-semibold mb-2">Enterprise Ready</h4>
            <p className="text-indigo-100">Built with scalability and security at its core</p>
          </div>

          <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6">
            <div className="flex items-center justify-center mb-4">
              <svg className="w-8 h-8 text-indigo-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
              </svg>
            </div>
            <h4 className="text-xl font-semibold mb-2">Future Ready</h4>
            <p className="text-indigo-100">Designed to scale globally with your growing needs</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);


const Footer = () => (
  <footer className="bg-white border-t border-gray-100 py-12">
    <div className="container mx-auto px-6">
      <div className="grid md:grid-cols-4 gap-8 mb-8">
        <div className="space-y-4">
          <Link to="/" smooth={true} duration={500} className="cursor-pointer">
            <EigenticLogo className="h-24 w-auto mb-4" />
          </Link>
          <img 
            src={msBadge} 
            alt="Microsoft for Startups" 
            className="h-20 w-auto" 
          />
        </div>
        
        <div>
          <h3 className="text-sm font-semibold text-gray-900 mb-4">Company</h3>
          <ul className="space-y-3 text-sm text-gray-600">
            <li>
              <Link 
                to="about-us" 
                smooth={true} 
                duration={500}
                offset={-100} 
                className="hover:text-indigo-600 transition-colors cursor-pointer"
              >
                About Us
              </Link>
            </li>
            <li>
              <Link 
                to="contact" 
                smooth={true} 
                duration={500}
                offset={-100} 
                className="hover:text-indigo-600 transition-colors cursor-pointer"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        
        <div>
          <h3 className="text-sm font-semibold text-gray-900 mb-4">Product</h3>
          <ul className="space-y-3 text-sm text-gray-600">
            <li>
              <Link 
                to="company-features" 
                smooth={true} 
                duration={500}
                offset={-100} 
                className="hover:text-indigo-600 transition-colors cursor-pointer"
              >
                Features
              </Link>
            </li>
            <li>
              <Link 
                to="pricing" 
                smooth={true} 
                duration={500}
                offset={-100} 
                className="hover:text-indigo-600 transition-colors cursor-pointer"
              >
                Pricing
              </Link>
            </li>
            <li>
              <Link 
                to="candidate-features" 
                smooth={true} 
                duration={500}
                offset={-100} 
                className="hover:text-indigo-600 transition-colors cursor-pointer"
              >
                For Candidates
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <h3 className="text-sm font-semibold text-gray-900 mb-4">Connect</h3>
          <ul className="space-y-3 text-sm text-gray-600">
            <li>
              <a 
                href="https://www.linkedin.com/company/eigentic" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="hover:text-indigo-600 transition-colors flex items-center gap-2"
              >
                <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                </svg>
                LinkedIn
              </a>
            </li>
            <li>
              <a 
                href="mailto:contact@eigentic.com" 
                className="hover:text-indigo-600 transition-colors"
              >
                contact@eigentic.com
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="border-t border-gray-100 pt-8 flex flex-col md:flex-row justify-between items-center">
        <p className="text-sm text-gray-600">
          © {new Date().getFullYear()} Eigentica Soft Solution Pvt Ltd.
        </p>
      </div>
    </div>
  </footer>
);
// Floating Action Button Component
const FloatingActionButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.pageYOffset > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!isVisible) return null;

  return (
    <motion.button
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="fixed bottom-8 right-8 bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
      </svg>
    </motion.button>
  );
};

const Layout = () => {
  return (
    <div className="min-h-screen bg-white">
      <Header />
      <main>
        <Hero />
        <WorkflowAnimation />

<CompanyFeatures/>     
  <CandidateFeatures />
  <PricingSection />
  <AboutUs />
        
      </main>
      <ContactSection />
      <Footer />
      <FloatingActionButton />
    </div>
  );
};

export default Layout;